<template>
  <BaseInput
    type="email"
    label="Correo electrónico"
    placeholder="Correo electrónico"
    :value="value"
    :error="error"
    v-bind="$attrs"
    @input="$emit('input', $event)"
  >
    <template slot="left-side">
      <svg class="h-4 w-4 fill-current ml-3" viewBox="0 0 20 20">
        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
      </svg>
    </template>
  </BaseInput>
</template>

<script>
import BaseInput from '@/components/BaseInput';

export default {
  name: 'EmailInput',
  components: {
    BaseInput,
  },
  props: {
    value: { type: [String, Number] },
    error: { type: String, default: null },
  },
};
</script>

<template>
  <div
    style="background-image: url(img/bg-login.jpg)"
    class="absolute inset-0 bg-no-repeat bg-cover flex items-center justify-items-center px-4"
  >
    <div class="bg-white w-full p-10 rounded-t-md shadow-md md:max-w-lg md:mx-auto">
      <div>
        <img class="w-56 mx-auto" src="../assets/logo.png" alt="Timelapse México" />
      </div>

      <h2 class="mt-8 font-semibold text-2xl text-center">Inicia Sesión</h2>

      <form @submit.prevent="login" class="mt-4">
        <div class="mt-3">
          <EmailInput v-model="form.email" :error="errors.first('email')" />
        </div>

        <div class="mt-4">
          <PasswordInput v-model="form.password" :error="errors.first('password')" />
        </div>

        <div class="mt-2 text-primary text-sm text-right">
          <router-link :to="{ name: 'forgot-password' }">¿Olvidaste tu contraseña?</router-link>
        </div>

        <div class="mt-4 text-sm text-red-600 text-center" :show="errors.has('login')">
          {{ errors.first('login') }}
        </div>

        <div class="mt-4 flex items-center justify-center">
          <button
            type="submit"
            class="bg-primary text-white text-sm px-12 py-3 rounded-md inline-flex items-center transition ease-in-out duration-150 hover:bg-primary-400 focus:outline-none focus:ring-4"
            :class="{ 'cursor-not-allowed': loading }"
            :disabled="loading"
          >
            <svg
              v-if="loading"
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Iniciar sesión
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import Errors from '@/util/Errors';
import EmailInput from '@/components/EmailInput';
import PasswordInput from '@/components/PasswordInput';

export default {
  components: { EmailInput, PasswordInput },
  name: 'Login',
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      loading: false,
      errors: new Errors(),
    };
  },

  methods: {
    async login() {
      this.loading = true;
      this.errors.clear();

      try {
        const credentials = {
          email: this.form.email,
          password: this.form.password,
        };

        await store.dispatch('auth/login', credentials);

        this.$router.replace({ name: 'home' });
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.errors.capture(error.response.data.errors);
        }
        this.loading = false;
      }
    },
  },
};
</script>
